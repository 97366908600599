/* purgecss start ignore */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,*{
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
}
 .bg-white.nodata{
    width: 65%;
    margin: 0 auto;
    border-radius: 15px;
 }
 
.md-steppers.md-theme-default{
  background:none;
  width:48%;
  margin: 0 auto;
}
.md-steppers.md-vertical .md-stepper-header {
    height: 71px;
    background: rgba(77, 101, 143, 0.1);
    margin: 15px 0;
    border-radius: 15px;
}
.md-steppers.md-vertical .md-stepper-content{
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  background: #fff;
  padding: 34px 40px;
  border-radius: 15px;
  font-size: 16px;
  color:#4D658F;
    line-height: 26px;
}
.md-steppers.md-theme-default .md-stepper:after, .md-steppers.md-theme-default .md-stepper:before{
  display: none !important;
}

.md-steppers.md-theme-default .md-stepper-number{
  background-color:#4D658F !important;
  width: 41px;
  height: 41px;
  border-radius: 24px;
  transition: .3s cubic-bezier(.4,0,.2,1);
  transition-property: color,background-color;
  will-change: color,background-color;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: */
}
.md-steppers.md-theme-default .md-active .md-stepper-number{
  background-color:#104CBA !important;
}
.title{
    font-family:'Prompt', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #170F49;
}
.description{
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    color: #4D658F;
    border-bottom: 1px solid rgba(77, 101, 143, 0.17);
    margin-bottom: 25px;
    padding-bottom: 30px;
}
ul.list-disc li{
  margin: 17px 17px 0px 0px;
    padding-left: 30px;
    background-image:url('/images/check-circle.png');
    background-repeat: no-repeat;
    background-position: 0 0px;
    margin-bottom: 10px;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    width: 47%
  }
  ul.list-disc li a {
    font-size: 16px;
    color: #104CBA !important;
    font-weight: 400;
  }
  .md-theme-default a:not(.md-button){
    font-size: 16px;
    color: #104CBA !important;
    font-weight: 400;
  }
  form{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 25px;
    background: #FFFFFF;
    border: 1px solid rgba(77, 101, 143, 0.2);
    border-radius: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .md-stepper-label{
    color: #4D658F;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    position: relative;
  }
  .md-stepper .md-active .md-stepper-label {
    color: #104CBA;
  }
  .md-stepper .md-active .md-stepper-label:after {
    background-color: #104CBA;
  }
  .md-stepper-label:after{
    content: '';
    background-color: #4D658F;
    width: 41px;
    height: 4px;
    top: 0;
    position: absolute;
    bottom: 0;
    margin: auto 9px;
  }
  .form-input, .form-textarea, .form-select, .swal2-select{

    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: 52px;
    border: 1px solid rgba(77, 101, 143, 0.2);
    border-radius: 8px;

    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #4D658F;
  }
  .form-select{
    min-height: 52px !important;
    height: auto !important;
  }
  .form-textarea{
    min-height: 120px !important;
    height: auto !important;
  }
  ::-webkit-input-placeholder,::-webkit-select-placeholder { /* Chrome/Opera/Safari */
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #4D658F !important;
}
::-moz-placeholder { /* Firefox 19+ */
    font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #4D658F !important;
}
:-ms-input-placeholder { /* IE 10+ */
    font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #4D658F !important;
}
:-moz-placeholder { /* Firefox 18- */
    font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #4D658F !important;
}


  /* .form-label{
    display: none !important;
  } */
  .md-button.btn, .btn-custom-color.btn{
    background: #104CBA !important;
    border-radius: 4.36735px;
    padding: 8px 25px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 0;
    text-transform: capitalize;
    letter-spacing: 0.9px;
    height: 51px;
  }
  .step-progress__step-label{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 20px;

    color: #000000 !important;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer;
}
.form-control[type=file] {
    overflow: hidden;
}
.browseFile .p-2{
  padding:0;
}
.browseFile .form-input{
  padding:0
}
.browseFile button {
    height: 50px;
    border-radius: 8px 0px 0px 8px;
    margin-left: 0px;
    padding: 0 19px;
    font-size: 16px;
    line-height: 28px;
    background: #104CBA !important;
    font-weight: 600;
}
.browseFile .flex-1{
  padding-left: 12px;
}
.ui.search.dropdown>.text {
     color: #4D658F !important;
    opacity: 1;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
    padding:7px 2px;
}
.ui.dropdown>.label {
    position: relative;
    padding-top: 12px;
    padding-right: calc(0.78571em + 15px);
    padding-bottom: 12px;
    margin: 4px 10px 5px 0;
}
.step-progress__step span{
    font-size: 30px;
}
.step-progress__step:after{
    height: 50px;
    width: 50px;
}
.step-progress__step-icon{
    font-size: 26px;
}
/* new css added */
.planner_main{
  background-color: #ffffff !important;
}
.md-stepper-label {
  font-size: 18px;
  line-height: 21.11px;
  font-weight: 500;
}
.md-steppers.md-vertical .md-stepper-header {
  border-radius: 10px;
}
.md-steppers.md-vertical .md-stepper-header .md-ripple {
  padding: 15px;
}
.md-steppers.md-vertical .md-stepper-content.md-active {
  box-shadow: 0px 0px 30px rgba(8, 15, 52, 0.09);
}
.md-steppers.md-vertical .md-stepper-content {
  padding: 25px !important;
}
.body-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: #4D658F;
}
.list-disc {
  margin: 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.form_parent{
  margin-top: 35px;
}
.md-button-content {
  font-size: 17px;
  font-weight: 700;
  color: #fff;
}
.lg_status {
  font-size: 19px;
  font-weight: 400;
}
.lg_status span{
  font-weight: 500;
}
.md-stepper-content {
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.md-stepper-header{
  transition: all .4s ease;
}
.md-stepper-content.md-active{
  -webkit-animation: showDown .6s;
          animation: showDown .6s ease-in-out;
}
@-webkit-keyframes showDown {
  from {
    opacity: 0;
    transform: translateY(-4%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes showDown {
  from {
    opacity: 0;
    transform: translateY(-4%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



@media only screen and (max-width: 768px) {
  .md-steppers.md-theme-default {
    width: 100%;
  }
  .step-progress__step:after {
    height: 30px;
    width: 30px;
  }
  .step-progress__step span {
    font-size: 12px;
  }
  .step-progress__step-icon {
    font-size: 16px;
  }
  form {
    padding: 18px;
  }
  ul.list-disc li {
    width: 100%;
  }
  .step-progress__step-label {
    font-size: 14px;
  }
  .description {
    margin-bottom: 6px;
    padding-bottom: 16px;
}
  
}

@media only screen and (max-width: 1080px) and (min-width: 768px) {
  .md-steppers.md-theme-default {
    width: 100%;
  }
  
}
/* purgecss end ignore */